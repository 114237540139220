import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { useGetCompanysQuery } from "../../libs/redux/slices/filterSlice/filterApiSlice";
import {
  useCreateAreaMutation,
  useCreateCompanyAreasMutation,
} from "../../libs/redux/slices/crearexxSlice/crearexxApiSlice";

import { setDictionaryErrors } from "../../libs/redux/slices/errorSlice/errorSlice";
import { dictionaryErrors } from "./configxx";

import { useDispatch } from "react-redux";

const Crearexx = ({ onClose }) => {
  const dispatch = useDispatch();
  dispatch(setDictionaryErrors({ dictionaryErrors }));
  const { data: companys, isLoading: isLoadingCompanys } = useGetCompanysQuery();
  const [insertArea, { isLoading: isLoadingArea }] = useCreateAreaMutation();
  const [insertCompanyAreas, { isLoading: isLoadingCompanyAreas }] = useCreateCompanyAreasMutation();
  const [validated, setValidated] = useState(false);
  const [descriptionArea, setDescriptionArea] = useState("");
  const [descriptionPath, setDescriptionPath] = useState("");
  const [nitsCompanys, setNitsCompanys] = useState([]);
  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity()) {
      await insertArea({ AREADESX: descriptionArea, AREAPATH: descriptionPath })
        .unwrap()
        .then((response) => {
          saveCompanyAreas(response.AREAIDXX);
        })
        .catch((rejected) => {});
    } else {
      event.preventDefault();
    }
    setValidated(true);
  };

  const saveCompanyAreas = async (areaID) => {
    await insertCompanyAreas({ EMPNITXX: nitsCompanys, AREAIDXX: areaID })
      .unwrap()
      .then(() => {
        onClose(false);
      })
      .catch();
  };

  return isLoadingArea || isLoadingCompanyAreas ? (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  ) : (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Nombre*</Form.Label>
          <InputGroup>
            <Form.Control
              value={descriptionArea}
              type="text"
              required
              onChange={(e) => {
                setDescriptionArea(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">Debe Diligenciar el Nombre del Area</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Descripcion del Path*</Form.Label>
          <InputGroup>
            <Form.Control
              value={descriptionPath}
              type="text"
              required
              onChange={(e) => {
                setDescriptionPath(e.target.value);
              }}
              minLength={8}
              maxLength={8}
            />
            <Form.Control.Feedback type="invalid">Debe Diligenciar la Descripcion del Path</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={12}>
          <Form.Label>Empresa:*</Form.Label>
          <Form.Select
            value={nitsCompanys}
            multiple
            required
            onChange={(e) => {
              setNitsCompanys([]);
              const nitsSelected = [];
              for (var i = 0; i < e.target.options.length; i++) {
                if (e.target.options[i].selected && e.target.options[i].value !== "") {
                  nitsSelected.push(e.target.options[i].value);
                }
              }
              setNitsCompanys(nitsSelected);
            }}
          >
            {isLoadingCompanys ? (
              <option value={""}>CARGANDO...</option>
            ) : (
              companys?.data?.map((company, index) => {
                return index === 0 ? (
                  <>
                    <option value={""}>[SELECCIONE]</option>
                    <option key={company.EMPNITXX} value={company.EMPNITXX}>
                      {company.EMPDESXX}
                    </option>
                  </>
                ) : (
                  <option key={company.EMPNITXX} value={company.EMPNITXX}>
                    {company.EMPDESXX}
                  </option>
                );
              })
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar las Empresas</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row md={12} className="mt-3">
        <Col className="offset-10" md={1}>
          <Button type="Submit">Guardar</Button>
        </Col>
        <Col md={1}>
          <Button
            onClick={(e) => {
              setValidated(false);
              setDescriptionArea("");
              setDescriptionPath("");
              setNitsCompanys([]);
            }}
          >
            Limpiar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Crearexx;
