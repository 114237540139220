import { apiSlice } from "../apiSlice";

export const crearexxApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createArea: builder.mutation({
      query: (parameters) => ({
        url: `areas`,
        method: "POST",
        body: parameters,
      }),
    }),
    createCompanyAreas: builder.mutation({
      query: (parameters) => ({
        url: `empresaAreas`,
        method: "POST",
        body: parameters,
      }),
    }),
  }),
});

export const { useCreateAreaMutation } = crearexxApiSlice;
export const { useCreateCompanyAreasMutation } = crearexxApiSlice;
