import { apiSlice } from "../apiSlice";

export const filterApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCompanys: builder.query({
      keepUnusedDataFor: 18000,
      query: () => ({
        url: `/empresasOpciones`,
        method: "GET",
      }),
    }),
    getAreasByCompany: builder.query({
      query: (parameters) => ({
        url: `/empresaAreas/${parameters.EMPNITXX}/${parameters.AREAIDXX}`,
        method: "GET",
      }),
      invalidatesTags: ["reset"],
    }),
    getSeriesByArea: builder.query({
      query: (parameters) => ({
        url: `/areasxseries/${parameters.EMPNITXX}/${parameters.AREAIDXX}/${parameters.SERIEIDX}`,
        method: "GET",
      }),
    }),
    getTypesBySerie: builder.query({
      query: (parameters) => ({
        url: `/serieportipos/${parameters.EMPNITXX}/${parameters.AREAIDXX}/${parameters.SERIEIDX}/${parameters.TIPOIDXX}`,
        method: "GET",
      }),
    }),
    getMetadataByCompanyAreaSerie: builder.query({
      query: (parameters) => ({
        url: `/tipometadatos/${parameters.EMPNITXX}/${parameters.AREAIDXX}/${parameters.SERIEIDX}/${parameters.TIPOIDXX}/${parameters.METIDXXX}`,
        method: "GET",
      }),
    }),
    getChargesPerTypeSerialArea: builder.query({
      query: () => ({
        url: `/cargosporareaserietipo`,
        method: "GET",
      }),
    }),
    getUsersByAreaSeriesType: builder.query({
      query: () => ({
        url: `/usuariosporareaserietipo`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetCompanysQuery } = filterApiSlice;
export const { useLazyGetAreasByCompanyQuery } = filterApiSlice;
export const { useLazyGetSeriesByAreaQuery } = filterApiSlice;
export const { useLazyGetTypesBySerieQuery } = filterApiSlice;
export const { useLazyGetMetadataByCompanyAreaSerieQuery } = filterApiSlice;
export const { useGetChargesPerTypeSerialAreaQuery } = filterApiSlice;
export const { useLazyGetUsersByAreaSeriesTypeQuery } = filterApiSlice;
