import React, { useState, useEffect } from "react";
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { useGetCompanysQuery, useLazyGetAreasByCompanyQuery } from "../../libs/redux/slices/filterSlice/filterApiSlice";
import {
  useCreateSerieMutation,
  useCreateCompanyAreaSerieMutation,
} from "../../libs/redux/slices/creserxxSlice/creserxxApiSlice";
import { apiSlice } from "../../libs/redux/slices/apiSlice";

import { setDictionaryErrors } from "../../libs/redux/slices/errorSlice/errorSlice";
import { dictionaryErrors } from "./configxx";

import { useDispatch } from "react-redux";

const Creserxx = ({ onClose }) => {
  const dispatch = useDispatch();
  dispatch(setDictionaryErrors({ dictionaryErrors }));
  const { data: companys, isLoading: isLoadingCompanys } = useGetCompanysQuery();
  const [findAreasByCompany, { data: areas, isLoading: isLoadingAreas, isUninitialized: isUninitializedAreas }] =
    useLazyGetAreasByCompanyQuery();
  const [insertSerie, { isLoading: isLoadingSerie }] = useCreateSerieMutation();
  const [insertCompanyAreaSerie, { isLoading: isLoadingCompanyAreaSerie }] = useCreateCompanyAreaSerieMutation();
  const [validated, setValidated] = useState(false);
  const [serieDescription, setSerieDescription] = useState("");
  const [descriptionPath, setDescriptionPath] = useState("");
  const [company, setCompany] = useState("");
  const [area, setArea] = useState("");

  useEffect(() => {
    if (company !== "") {
      findAreasByCompany({ EMPNITXX: company, AREAIDXX: "null" }).catch(() => console.log("llego por aqui"));
    }
  }, [company, findAreasByCompany]);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() && area !== "") {
      await insertSerie({ SERIEDES: serieDescription, SERIEPAT: descriptionPath })
        .unwrap()
        .then((response) => {
          saveCompanyAreaSerie(response.data.SERIEIDX);
        })
        .catch();
    } else {
      event.preventDefault();
    }
    setValidated(true);
  };

  const saveCompanyAreaSerie = async (serieID) => {
    await insertCompanyAreaSerie({ EMPNITXX: company, AREAIDXX: area, SERIEIDX: serieID })
      .unwrap()
      .then(() => {
        onClose(false);
      })
      .catch();
  };
  return isLoadingSerie || isLoadingCompanyAreaSerie ? (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  ) : (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Serie*</Form.Label>
          <InputGroup>
            <Form.Control
              value={serieDescription}
              type="text"
              required
              onChange={(e) => {
                setSerieDescription(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">Debe Diligenciar el Nombre de la Serie</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Descripcion del Path*</Form.Label>
          <InputGroup>
            <Form.Control
              value={descriptionPath}
              type="text"
              required
              onChange={(e) => {
                setDescriptionPath(e.target.value);
              }}
              minLength={8}
              maxLength={8}
            />
            <Form.Control.Feedback type="invalid">Debe Diligenciar la Descripcion del Path</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={6}>
          <Form.Label>Empresa:*</Form.Label>
          <Form.Select
            value={company}
            required
            onChange={(e) => {
              setCompany(e.target.value);
              dispatch(apiSlice.util.invalidateTags(["reset"]));
            }}
          >
            {isLoadingCompanys ? (
              <option value={""}>CARGANDO...</option>
            ) : (
              companys?.data?.map((company, index) => {
                return index === 0 ? (
                  <>
                    <option value={""}>[SELECCIONE]</option>
                    <option key={company.EMPNITXX} value={company.EMPNITXX}>
                      {company.EMPDESXX}
                    </option>
                  </>
                ) : (
                  <option key={company.EMPNITXX} value={company.EMPNITXX}>
                    {company.EMPDESXX}
                  </option>
                );
              })
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar una Empresa</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>Proceso:*</Form.Label>
          <Form.Select
            onChange={(e) => {
              console.log(e.target.value);
              setArea(e.target.value);
            }}
            value={area}
          >
            {isLoadingAreas || isUninitializedAreas ? (
              <option value={""}>[SELECCIONE]</option>
            ) : (
              areas?.data.map((area, index) => {
                return index === 0 ? (
                  <>
                    <option value={""}>[SELECCIONE]</option>
                    <option key={`${area.EMPNITXX}-${area.AREAIDXX}`} value={area.AREAIDXX}>
                      {area.AREADESX}
                    </option>
                  </>
                ) : (
                  <option key={`${area.EMPNITXX}-${area.AREAIDXX}`} value={area.AREAIDXX}>
                    {area.AREADESX}
                  </option>
                );
              })
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar un Proceso</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row md={12} className="mt-3">
        <Col className="offset-10" md={1}>
          <Button type="Submit">Guardar</Button>
        </Col>
        <Col md={1}>
          <Button
            onClick={(e) => {
              setValidated(false);
              setSerieDescription("");
              setDescriptionPath("");
              setCompany("");
              setArea("");
            }}
          >
            Limpiar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Creserxx;
