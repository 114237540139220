import { apiSlice } from "../apiSlice";

export const cremetxxApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOptions: builder.query({
      query: () => ({
        url: `metadatosOpciones`,
        method: "GET",
      }),
    }),
    getMetadata: builder.query({
      query: ({ fields, filters }) => ({
        url:
          `metadatos?` +
          (fields !== "" ? fields : "") +
          (fields !== "" && filters !== "" ? "&" : "") +
          (filters !== "" ? filters : ""),
        method: "GET",
      }),
    }),
    createMetadata: builder.mutation({
      query: (parameters) => ({
        url: `metadatos`,
        method: "POST",
        body: parameters,
      }),
    }),
  }),
});

export const { useGetOptionsQuery } = cremetxxApiSlice;
export const { useGetMetadataQuery } = cremetxxApiSlice;
export const { useCreateMetadataMutation } = cremetxxApiSlice;
