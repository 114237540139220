import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { columnsTransform } from "./columnsTransform";
import {
  useGetDataTrackingQuery,
  useDeleteCompanyAreaSerieTypeMetadataMutation,
  useUpdateChargesUserAreaSeriesTypeMutation,
} from "../../libs/redux/slices/tablexxxSlice/tableApiSlice";
import { getFilters } from "../../libs/redux/slices/filterSlice/filterSlice";
import { useInactivatePermisionByCargoMutation } from "../../libs/redux/slices/creapermSlice/creapermApiSlice";
import { useInactivatePermisionByUserMutation } from "../../libs/redux/slices/crepermuSlice/creaprusApiSlice";
import { setShowMessage } from "../../libs/redux/slices/confirmxxSlice/confirmxxSlice";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";

const Tablexxx = ({ columns, URL, buttons, setComponent, setShow, setRow }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(process.env.REACT_APP_GENIO_ROWS_TABLE);
  const [deleteCompanyAreaSerieTypeMetadata] = useDeleteCompanyAreaSerieTypeMetadataMutation();
  const [updateChargesUserAreaSeriesType] = useUpdateChargesUserAreaSeriesTypeMutation();
  const [ inactivatePermisionUser ] = useInactivatePermisionByUserMutation();
  const [ inactivatePermisionCargo ] = useInactivatePermisionByCargoMutation();
  const filters = useSelector(getFilters);
  const dispatch = useDispatch();
  const optionsPagination = {
    rowsPerPageText: "Filas por pagina",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };
  const {
    isError,
    isLoading,
    data: dataResponse,
    isFetching,
  } = useGetDataTrackingQuery({
    stringFilters: filters,
    URL: URL,
    page: page,
    per_page: perPage,
  });

  const updateRow = (row, position, value) => {
    let updatedRow = { ...row };

    if (updatedRow.hasOwnProperty(position)) {
      updatedRow[position] = value;
    } else {
      updatedRow = {
        ...updatedRow,
        [position]: value,
      };
    }
    handleRowUpdate(updatedRow);

    return updatedRow;
  };

  //const [updatedData, setUpdatedData] = useState(null);
  const [updatedData, setUpdatedData] = useState([]);

  useEffect(() => {
    setUpdatedData(dataResponse?.data || []);
  }, [dataResponse]);

  const handleRowUpdate = (updatedRow) => {
    const rowIndex = updatedData.findIndex((item) => {
      return item.CARIDXXX !== undefined
        ? item.CARIDXXX === updatedRow.CARIDXXX && item.EMPNITXX === updatedRow.EMPNITXX
        : item.USRIDXXX === updatedRow.USRIDXXX && item.EMPNITXX === updatedRow.EMPNITXX;
    });

    if (rowIndex !== -1) {
      const updatedDataCopy = [...updatedData];
      updatedDataCopy[rowIndex] = updatedRow;
      setUpdatedData(updatedDataCopy);
    }
  };

  useEffect(() => {}, [updatedData]);

  const onPageChange = (page) => {
    setPage(page);
  };

  //Cantidad de filas por pagina
  const onPerRowsChange = (newPerPage) => {
    setPerPage(newPerPage);
  };
  const getMessage = (message) => {
    dispatch(setShowMessage({ message }));
  };
  const columnsAplicateFormat = columnsTransform(
    columns,
    buttons,
    deleteCompanyAreaSerieTypeMetadata,
    setComponent,
    setShow,
    setRow,
    updateChargesUserAreaSeriesType,
    updateRow,
    updatedData,
    inactivatePermisionCargo,
    inactivatePermisionUser,
    getMessage
  );
  return (
    <>
      {isError ? (
        "Sin Conexion"
      ) : isLoading || isFetching ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <DataTable
          noDataComponent="No se Encontraron Registros"
          columns={columnsAplicateFormat}
          data={updatedData}
          progressPending={isFetching}
          paginationPerPage={dataResponse.meta.per_page}
          pagination
          paginationServer
          paginationTotalRows={dataResponse.meta.total}
          paginationDefaultPage={dataResponse.meta.current_page}
          onChangePage={onPageChange}
          onChangeRowsPerPage={onPerRowsChange}
          paginationComponentOptions={optionsPagination}
        />
      )}
    </>
  );
};

export default Tablexxx;
