export const columnsTracking = [
  { id: "idEmpresa", title: "Empresa", width: "15%", position: "EMPDESXX" },
  { id: "idArea", title: "Proceso", width: "10%", position: "AREADESX" },
  { id: "idSerie", title: "Serie", width: "10%", position: "SERIEDES" },
  { id: "idTipoDocumental", title: "Tipo Documental", width: "10%", position: "TIPODESX" },
  { id: "idMetadato", title: "Metadato", width: "15%", position: "METDESXX" },
  { id: "idTipoPadre", title: "Tipo Padre", width: "10%", position: "TIPODEPA" },
  { id: "idCreado", title: "Creado por", width: "10%", position: "USRNOMXX" },
  { id: "idFechaCreacion", title: "Fecha de Creacion", width: "10%", position: "REGFECXX" },
];

export const buttons = [
  { id: "idEliminar", class: "bi bi-trash" },
  { id: "idVer", class: "bi bi-eye-fill" },
];
