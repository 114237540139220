import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import {
  useGetCompanysQuery,
  useLazyGetAreasByCompanyQuery,
  useLazyGetSeriesByAreaQuery,
  useLazyGetTypesBySerieQuery,
} from "../../libs/redux/slices/filterSlice/filterApiSlice";
import { useGetMetadataQuery } from "../../libs/redux/slices/cremetxxSlice/cremetxxApiSlice";
import { useCreateCompanyAreaSerieTypeMetadataMutation } from "../../libs/redux/slices/asometxxSlice/asometxxApiSlice";

const Asometxx = ({ onClose, row }) => {
  const [validated, setValidated] = useState(false);
  const [company, setCompany] = useState("");
  const [area, setArea] = useState("");
  const [serie, setSerie] = useState("");
  const [type, setType] = useState("");
  const [metadata, setMetadata] = useState("");
  const [mandatory, setMandatoty] = useState();

  const { data: companys, isLoading: isLoadingCompanys } = useGetCompanysQuery();
  const { data: metadatas, isLoading: isLoadingMetadata } = useGetMetadataQuery({
    fields: "fields=METIDXXX,METDESXX",
    filters: "filters[REGESTXX]=ACTIVO",
  });
  const [insertCompanyAreaSerieTypeMetadata, { isLoading: isCompanyAreaSerieTypeMetadata }] =
    useCreateCompanyAreaSerieTypeMetadataMutation();

  const [findAreasByCompany, { data: areas, isLoading: isLoadingAreas, isUninitialized: isUninitializedAreas }] =
    useLazyGetAreasByCompanyQuery();

  const [findSeriesByArea, { data: series, isLoading: isLoadingSeries, isUninitialized: isUninitializedSeries }] =
    useLazyGetSeriesByAreaQuery();

  const [findTypesBySerie, { data: types, isLoading: isLoadingTypes, isUninitialized: isUninitializedTypes }] =
    useLazyGetTypesBySerieQuery();

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity()) {
      await insertCompanyAreaSerieTypeMetadata({
        EMPNITXX: company,
        AREAIDXX: area,
        SERIEIDX: serie,
        TIPOIDXX: type,
        METIDXXX: metadata,
        BANOBLXX: mandatory,
      })
        .unwrap()
        .then(() => {
          onClose(false);
        })
        .catch();
    } else {
      event.preventDefault();
    }
    setValidated(true);
  };

  useEffect(() => {
    if (company !== "") {
      findAreasByCompany({ EMPNITXX: company, AREAIDXX: "null" });
    }
  }, [company, findAreasByCompany]);

  useEffect(() => {
    if (company !== "" && area !== "") {
      findSeriesByArea({ EMPNITXX: company, AREAIDXX: area, SERIEIDX: "null" });
    }
  }, [company, area, findSeriesByArea]);

  useEffect(() => {
    if (company !== "" && area !== "" && serie !== "") {
      findTypesBySerie({
        EMPNITXX: company,
        AREAIDXX: area,
        SERIEIDX: serie,
        TIPOIDXX: "null",
      });
    }
  }, [company, area, serie, findTypesBySerie]);

  useEffect(() => {
    if (!isLoadingCompanys && row?.EMPNITXX) {
      setCompany(row.EMPNITXX);
    }
  }, [isLoadingCompanys, row]);

  useEffect(() => {
    if (!isLoadingCompanys && !isLoadingAreas && row?.AREAIDXX) {
      setArea(row.AREAIDXX);
    }
  }, [isLoadingCompanys, isLoadingAreas, row]);

  useEffect(() => {
    if (!isLoadingCompanys && !isLoadingAreas && !isLoadingSeries && row?.SERIEIDX) {
      setSerie(row.SERIEIDX);
    }
  }, [isLoadingCompanys, isLoadingAreas, isLoadingSeries, row]);

  useEffect(() => {
    if (!isLoadingCompanys && !isLoadingAreas && !isLoadingSeries && !isLoadingTypes && row?.TIPOIDXX) {
      setType(row.TIPOIDXX);
    }
  }, [isLoadingCompanys, isLoadingAreas, isLoadingSeries, isLoadingTypes, row]);

  useEffect(() => {
    if (
      !isLoadingCompanys &&
      !isLoadingAreas &&
      !isLoadingSeries &&
      !isLoadingTypes &&
      !isLoadingMetadata &&
      row?.METIDXXX
    ) {
      setMetadata(row.METIDXXX);
      setMandatoty(row.BANOBLXX);
    }
  }, [isLoadingCompanys, isLoadingAreas, isLoadingSeries, isLoadingTypes, isLoadingMetadata, row]);

  return isCompanyAreaSerieTypeMetadata || isLoadingCompanys || isLoadingMetadata ? (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  ) : (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Empresa:*</Form.Label>
          <Form.Select
            value={company}
            required
            onChange={(e) => {
              setCompany(e.target.value);
            }}
          >
            {isLoadingCompanys ? (
              <option value={""}>CARGANDO...</option>
            ) : (
              companys?.data?.map((company, index) => {
                return index === 0 ? (
                  <>
                    <option value={""}>[SELECCIONE]</option>
                    <option key={company.EMPNITXX} value={company.EMPNITXX}>
                      {company.EMPDESXX}
                    </option>
                  </>
                ) : (
                  <option key={company.EMPNITXX} value={company.EMPNITXX}>
                    {company.EMPDESXX}
                  </option>
                );
              })
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar una Empresa</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md={4}>
          <Form.Label>Proceso:*</Form.Label>
          <Form.Select
            onChange={(e) => {
              setArea(e.target.value);
            }}
            required
            value={area}
          >
            {isLoadingAreas || isUninitializedAreas ? (
              <option value={""}>[SELECCIONE]</option>
            ) : (
              areas?.data?.map((area, index) => {
                return index === 0 ? (
                  <>
                    <option value={""}>[SELECCIONE]</option>
                    <option key={`${area.EMPNITXX}-${area.AREAIDXX}`} value={area.AREAIDXX}>
                      {area.AREADESX}
                    </option>
                  </>
                ) : (
                  <option key={`${area.EMPNITXX}-${area.AREAIDXX}`} value={area.AREAIDXX}>
                    {area.AREADESX}
                  </option>
                );
              })
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar un Proceso</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md={4}>
          <Form.Label>Serie:*</Form.Label>
          <Form.Select
            onChange={(e) => {
              setSerie(e.target.value);
            }}
            required
            value={serie}
          >
            {isLoadingSeries || isUninitializedSeries ? (
              <option value={""}>[SELECCIONE]</option>
            ) : (
              series?.data?.map((serie, index) => {
                return index === 0 ? (
                  <>
                    <option value={""}>[SELECCIONE]</option>
                    <option key={`${serie.EMPNITXX}-${serie.AREAIDXX}-${serie.SERIEIDX}`} value={serie.SERIEIDX}>
                      {serie.SERIEDES}
                    </option>
                  </>
                ) : (
                  <option key={`${serie.EMPNITXX}-${serie.AREAIDXX}-${serie.SERIEIDX}`} value={serie.SERIEIDX}>
                    {serie.SERIEDES}
                  </option>
                );
              })
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar una Serie</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Tipo Documental:*</Form.Label>
          <Form.Select
            onChange={(e) => {
              setType(e.target.value);
            }}
            required
            value={type}
          >
            {isLoadingTypes || isUninitializedTypes ? (
              <option value={""}>[SELECCIONE]</option>
            ) : (
              types?.data?.map((types, index) => {
                return index === 0 ? (
                  <>
                    <option value={""}>[SELECCIONE]</option>
                    <option
                      key={`${types.EMPNITXX}-${types.AREAIDXX}-${types.SERIEIDX}-${types.TIPOIDXX}`}
                      value={types.TIPOIDXX}
                    >
                      {types.TIPODESX}
                    </option>
                  </>
                ) : (
                  <option
                    key={`${types.EMPNITXX}-${types.AREAIDXX}-${types.SERIEIDX}-${types.TIPOIDXX}`}
                    value={types.TIPOIDXX}
                  >
                    {types.TIPODESX}
                  </option>
                );
              })
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar un Tipo Documental</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md={4}>
          <Form.Label>Metadato:*</Form.Label>
          <Form.Select
            value={metadata}
            required
            onChange={(e) => {
              setMetadata(e.target.value);
            }}
          >
            {isLoadingMetadata ? (
              <option value={""}>CARGANDO...</option>
            ) : (
              metadatas?.data?.map((responseMetadata, index) => {
                return index === 0 ? (
                  <>
                    <option value={""}>[SELECCIONE]</option>
                    <option key={responseMetadata.METIDXXX} value={responseMetadata.METIDXXX}>
                      {responseMetadata.METDESXX}
                    </option>
                  </>
                ) : (
                  <option key={responseMetadata.METIDXXX} value={responseMetadata.METIDXXX}>
                    {responseMetadata.METDESXX}
                  </option>
                );
              })
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar una Metadato</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md={4}>
          <Form.Label>Obligatoriedad*</Form.Label>
          <Form.Select
            value={mandatory}
            required
            onChange={(e) => {
              setMandatoty(e.target.value);
            }}
          >
            <option value={""}>[SELECCIONE]</option>
            <option value="SI">SI</option>
            <option value="NO">NO</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar la Obligatoriedad</Form.Control.Feedback>
        </Form.Group>
      </Row>
      {Object.keys(row).length === 0 ? (
        <Row md={12} className="mt-3">
          <Col className="offset-10" md={1}>
            <Button type="Submit">Guardar</Button>
          </Col>
          <Col md={1}>
            <Button
              onClick={(e) => {
                setValidated(false);
                setCompany("");
                setArea("");
                setSerie("");
                setType("");
                setMetadata("");
                setMandatoty("");
              }}
            >
              Limpiar
            </Button>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </Form>
  );
};

export default Asometxx;
