import { apiSlice } from "../apiSlice";

export const creserxxApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createSerie: builder.mutation({
      query: (parameters) => ({
        url: `series`,
        method: "POST",
        body: parameters,
      }),
    }),
    createCompanyAreaSerie: builder.mutation({
      query: (parameters) => ({
        url: `areasxseries`,
        method: "POST",
        body: parameters,
      }),
    }),
  }),
});

export const { useCreateSerieMutation } = creserxxApiSlice;
export const { useCreateCompanyAreaSerieMutation } = creserxxApiSlice;
