import React, { useState } from "react";
import Navbarxx from "../components/Navbarxx";
import Tabstrux from "../components/Tabstrux";
import Crearexx from "../components/Crearexx";
import Creserxx from "../components/Creserxx";
import Cretipxx from "../components/Cretipxx";
import Cremetxx from "../components/Cremetxx";
import Asometxx from "../components/Asometxx";
import Filtersx from "../components/Filtersx";

import { Container, Row, Col, Image, Button, Modal } from "react-bootstrap";

const AdminPage = () => {
  const [component, setComponent] = useState("");
  const [show, setShow] = useState(false);
  const [row, setRow] = useState({});
  let oComponent;
  switch (component) {
    case "PROCESO":
      oComponent = <Crearexx onClose={setShow} />;
      break;
    case "SERIE":
      oComponent = <Creserxx onClose={setShow} />;
      break;
    case "TIPO":
      oComponent = <Cretipxx onClose={setShow} />;
      break;
    case "METADATO":
      oComponent = <Cremetxx onClose={setShow} />;
      break;
    case "ASOCIAR METADATO":
      oComponent = <Asometxx onClose={setShow} row={row} />;
      break;
    default:
      break;
  }
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} className="modal-xl">
        <Modal.Header closeButton>
          <Modal.Title>CREAR {component}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{oComponent}</Modal.Body>
      </Modal>
      <Navbarxx path={"admin"} />
      <Container>
        <Row className="justify-content-md-center">
          <Col xxl={4} xl={6} md={10} className="align-self-center mt-1">
            <Image
              src="https://gestordocumental.repremundo.com.co/sites/default/files/Genio%20documental1-33.png"
              width="95%"
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Button
              className="w-100"
              onClick={() => {
                setComponent("PROCESO");
                setShow(true);
              }}
            >
              Crear Proceso
            </Button>
          </Col>
          <Col>
            <Button
              className="w-100"
              onClick={() => {
                setComponent("SERIE");
                setShow(true);
              }}
            >
              Crear Serie
            </Button>
          </Col>
          <Col>
            <Button
              className="w-100"
              onClick={() => {
                setComponent("TIPO");
                setShow(true);
              }}
            >
              Crear Tipo
            </Button>
          </Col>
          <Col>
            <Button
              className="w-100"
              onClick={() => {
                setComponent("METADATO");
                setShow(true);
              }}
            >
              Crear Metadato
            </Button>
          </Col>
          <Col>
            <Button
              className="w-100"
              onClick={() => {
                setComponent("ASOCIAR METADATO");
                setRow({});
                setShow(true);
              }}
            >
              Asociar Metadato
            </Button>
          </Col>
        </Row>
        <Row>
          <Filtersx />
        </Row>
        <Row className="mt-3">
          <Tabstrux setComponent={setComponent} setShow={setShow} setRow={setRow} />
        </Row>
      </Container>
    </>
  );
};

export default AdminPage;
