import React from "react";
import { columnsTracking, buttons } from "./configxx";
import Tablexxx from "../Tablexxx";

const Tabstrux = ({ setComponent, setShow, setRow }) => {
  return (
    <Tablexxx
      columns={columnsTracking}
      URL={"tipometadatos"}
      buttons={buttons}
      setComponent={setComponent}
      setShow={setShow}
      setRow={setRow}
    />
  );
};

export default Tabstrux;
